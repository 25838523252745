import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import Slide_1 from '../../../assets/images/slider/1.jpg';
import Placeholder from '../../../assets/images/thumbnail-default.jpg';

class AboutUs extends Component {
    render() {
        return (<section className="about-us style-two wow fadeIn">
            <div className="auto-container">
                <div className="row">
                    <div className="info-column col-xl-6 col-lg-7 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title ">
                                <span className="title">SHRI RAMDAWAR PANDEY MAHAVIDYALAYA</span>
                                <h2>About College</h2>
                            </div>
                            <div className="text">Shri Ramdawar Pandey Mahavidyalaya, Laradpur, Oril, Phoolpur, Azamgarh in the honour of Late Shri Ramdawar Pandey ji, (Former Reputed Headmaster of a Junior High School), was established in 2005, run by Sri Ramdawar Anusuchit Vidyalaya evam Shiksha Samiti (Society) established on 31st March, 1995; Continuous qualitative and quantitative growth, excellence in academic and administrative activities. Started with academic course i.e. B.A. (Bachelor of Arts) in 2005, the institution now has affiliation in graduation (i.e. Bachelor of Arts and Bachelor of Science), post-graduation (i.e. Master of Arts) as well as teacher education programmes (i.e. Bachelor of Education and D.El.Ed. also known as B.T.C.)
                </div>
                        </div>
                    </div>
                    {/* Video Column */}
                    <div className="video-column col-xl-6 col-lg-5 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="video-box">
                                <div className="video-patch d-block" style={{ width: '90%' }}></div>
                                <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                    <img src={Slide_1} id="aboutus_img" alt="college" />
                                </LazyLoad>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
    }
}
export default AboutUs;