import React, { Component } from 'react';
import * as emailjs from 'emailjs-com';
import url from '../../../assets/images/contacthome.jpg';
import LazyLoad from 'react-lazyload';
import Placeholder from '../../../assets/images/thumbnail-default.jpg';


class HomeContact extends Component {
  constructor() {
    super();
    this.state = {
      submitting: false,
      submitted: false,
      error: false,
      buttonState: '',
      formFields: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        text: ''
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onFieldsChange = this.onFieldsChange.bind(this);
    this.onHideSuccess = this.onHideSuccess.bind(this);
    this.successMessage = this.successMessage.bind(this);
    this.onHideError = this.onHideError.bind(this);
    this.errorMessage = this.errorMessage.bind(this);

  }

  onSubmit(e) {
    let { formFields } = this.state;
    e.preventDefault();

    emailjs.send('gmail', 'contact_us', formFields, 'user_T8ucaGcoEhmTATc4WEjM1')
      .then((response) => {
        this.setState({ submitted: true, error: false });
      }, (err) => {
        this.setState({ error: true, submitted: false });
      });
    this.setState({ formFields: { name: '', email: '', phone: '', subject: '', text: '' } });
  }

  onFieldsChange(e) {
    let { formFields } = this.state;
    formFields = { ...formFields };
    formFields[e.target.name] = e.target.value;
    this.setState({ formFields });
  }

  onHideSuccess() {
    this.setState({ submitted: false });
  }

  onHideError() {
    this.setState({ error: false });
  }

  successMessage() {
    if (this.state.submitted) {
      return (
        <div className="alert alert-success">
          <strong>Thank you!</strong>
          <button onClick={this.onHideSuccess} type="button" className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  errorMessage() {
    if (this.state.error) {
      return (
        <div className="alert alert-danger">
          <strong>Something Went Wrong</strong>
          <button onClick={this.onHideError} type="button" className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  render() {
    const { formFields: {
      name,
      email,
      phone,
      subject,
      text,
    } } = this.state;

    return (

      <section className="call-back-section wow fadeIn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="auto-container clearfix">
                <div className="column">
                  <div className="sec-title">
                    <span className="title">SHRI RAMDAWAR PANDEY MAHAVIDYALAYA</span>
                    <h2>Enquiry Now</h2>
                  </div>
                  <div className="request-form">
                    <form id="contactForm"
                      onSubmit={this.onSubmit}>
                      {this.successMessage()}
                      {this.errorMessage()}
                      <div className="form-group">
                        <input type="text"
                          name="name"
                          id="name"
                          placeholder=" Name"
                          data-error="Please enter your name"
                          value={name}
                          onChange={this.onFieldsChange} required />
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="form-group">
                            <input type="email"
                              name="email"
                              id="email"
                              placeholder=" Email"
                              data-error="Please enter your email"
                              value={email}
                              onChange={this.onFieldsChange} required />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 mobile-block">
                          <div className="form-group">
                            <input type="number"
                              className="mobile-field"
                              name="phone"
                              id="mobile"
                              placeholder="Contact Number"
                              value={phone}
                              onChange={this.onFieldsChange} required maxLength={10} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="form-group">
                            <input type="text"
                              name="subject"
                              id="subject"
                              placeholder=" Subject"
                              value={subject}
                              onChange={this.onFieldsChange} required />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="form-group">
                            <textarea type="text"
                              name="text"
                              id="message"
                              cols="30"
                              rows="5"
                              data-error="Write your message"
                              placeholder="Your Message"
                              value={text}
                              onChange={this.onFieldsChange} required />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-5 pb-5 placements_box_title">
                          <div className="form-group" id="msgSubmit">
                            <button className="theme-btn btn-style-two" type="submit" name="submit-form">Submit Now</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                <img src={url} alt="college" className="img-fluid" />
              </LazyLoad>
            </div>
          </div>

        </div>
      </section>

    );
  }
}


export default HomeContact;