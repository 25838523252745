import React, { Component } from 'react';
import buildingPlan from '../../assets/11_Building_Plan.pdf';

class Footer extends Component {
    render() {
        return (
            <footer className="main-footer wow fadeIn" style={{}}>
                <div className="auto-container ">
                    <div className="call-to-action footer-cta" style={{}}>
                        <div className="clearfix">
                            <div className="title-column">
                                <div className="sec-title light">
                                    <span className="title">Call Us </span>
                                    <h2><a href="tel:+919452517828"> +91-9452517828</a>, <a href="tel:+918419811921">8419811921</a></h2>
                                </div>
                            </div>
                            <div className="button-column  pt-2">
                                <a href="#/" className="theme-btn btn-style-one"><i className="la la-cloud-download" /> &nbsp; Download Admission Prospectus</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="auto-container">
                    <div className="widgets-section">
                        <div className="row">
                            <div className="upper-column col-lg-4 col-md-12 col-sm-12 ">
                                <div className="footer-widget contact-widget pr-0 pr-sm-2">
                                    <div className="widget-content">
                                        <div className="footer-logo mb-3">
                                            <h2 className="footertitle">About College</h2>
                                        </div>
                                        <p>Shri Ramdawar Pandey Mahavidyalaya, in the honour of Late Shri Ramdawar Pandey ji, (Former Reputed Headmaster of a Junior High School), was established in 2005, run by Shri Ramdawar Anusuchit Vidyalaya evam Shiksha Samiti (Society) established on 31st March, 1995.</p>
                                    </div>
                                    <div className="social-links mt-2">
                                        <ul className="social-icon-two text-left">
                                            <li className="mr-0"><a href="#/"><i className="la la-facebook" /></a></li>
                                            <li className="mr-0"><a href="#/"><i className="la la-instagram" /></a></li>
                                            <li className="mr-0"><a className="text-white" href="#/"><i className="la la-linkedin" /></a></li>
                                            <li><a className="text-white" href="#/"><i className="la la-twitter" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-column col-lg-4 col-md-12 col-sm-12">
                                <div className="footer-widget cities-widget">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <div className="widget-content">
                                        <ul className="list clearfix">
                                            <li><a href="#/">Download Prospectus</a></li>
                                            <li><a href="http://ncte.gov.in/Website/Index.aspx" target="_blank" rel="noopener noreferrer">National Council for Teacher Education</a></li>
                                            <li><a href="http://www.vbspu.ac.in" target="_blank" rel="noopener noreferrer">Veer Bahadur Singh Purvanchal University </a></li>
                                            <li><a href="http://scertup.org.in" target="_blank" rel="noopener noreferrer">State Council of Educational Research and Training, U.P. </a></li>
                                            <li><a href={buildingPlan} target="_blank" rel="noopener noreferrer">Infrastructure Facilties </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/*Footer Column*/}
                            <div className="footer-column col-lg-4 col-md-12 col-sm-12">
                                <div className="footer-widget contact-widget">
                                    <h2 className="widget-title">Get in Touch</h2>
                                    <div className="widget-content">
                                        <ul className="contact-list">
                                            <li><span className="la la-map-marker" /><b>Shri Ramdawar Pandey Mahavidyalaya</b> <br />
                                                Laradpur, Oril, Phoolpur, <br />
                                                Azamgarh,<br />
                                                Uttar Pradesh-223222</li>
                                            <li><span className="la la-phone" /> <a href="tel:+918419811921"> +91-8419811921</a>
                                                <a href="tel:+9452517828,"> +91-9452517828</a></li>
                                            <li><span className="la la-envelope" /><a href="mailto:brdpcollege@gmail.com">brdpcollege@gmail.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Footer Bottom*/}
                <div className="footer-bottom">
                    <div className="auto-container">
                        {/*Scroll to top*/}
                        <div className="scroll-to-top scroll-to-target" data-target="html"><span className="la la-angle-double-up" /></div>
                        <div className="inner-container clearfix">
                            <div className="footer-nav">
                                <ul className="clearfix">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="#/">Terms &amp; Conditions</a></li>
                                    <li><a href="#/">Privacy Policy</a></li>
                                </ul>
                            </div>
                            <div className="copyright-text">
                                <p>Copyright @ Shri Ramdawar Pandey Mahavidyalaya, Developed by <a href="https://www.raysteedsinfotech.com/" target="_blank" rel="noopener noreferrer">Raysteeds Infotech Pvt. Ltd. </a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;