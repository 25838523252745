import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import Placeholder from '../../assets/images/thumbnail-default.jpg';
import img1 from '../../assets/images/Events/O03C1P_full.jpg';
import img2 from '../../assets/images/Events/HB283A_full.jpg';
import img3 from '../../assets/images/Events/O18355_full.jpg';
import img4 from '../../assets/images/Events/7MY0I8_full.jpg';
import img5 from '../../assets/images/Events/QR6Q54_full.jpg';
import img6 from '../../assets/images/Events/KVBE8A_full.jpg';

const events = [

    {
        Name: "Orientation Program-2019",
        img: `${img4}`

    },
    {
        Name: "News Writing Competition",
        img: `${img2}`
    },
    {
        Name: "National Media Fest 2K18",
        img: `${img3}`
    },
    {
        Name: "North East Carnival",
        img: `${img6}`
    },
    {
        Name: "Anchoring Competition",
        img: `${img5}`
    },
    {
        Name: "Briefing Session for MBA students",
        img: `${img1}`
    },
];

class Events extends Component {
    render() {
        return (
            <section className="about-us style-two wow fadeIn animated" style={{ visibility: 'visible', animationName: 'fadeIn' }} id="events">
                <div className="auto-container">
                    <div className="row">
                        <div className="info-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="sec-title float-left">
                                    <span className="title">Shri Ramdawar Pandey Mahavidyalaya</span>
                                    <h2> Events</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="info-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="inner-column">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="info-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <ul className="row">
                                    {events.map(event => (
                                        <li className="col-lg-4 col-md-6 col-12">
                                            <div className="single-school-event mt-4 mb-2">
                                                <figure className="position-relative"
                                                // style={{
                                                //     backgroundImage: (`${ event.img }`)
                                                // }}
                                                >
                                                    <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                                        <img src={event.img} alt="event"></img>
                                                    </LazyLoad>
                                                    <div className="event-detail">
                                                        <h4>{event.Name}</h4>
                                                        <p />
                                                    </div>
                                                </figure>
                                            </div>
                                        </li>
                                    ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Events;