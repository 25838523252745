import React, { Component } from 'react';
import { Form } from "react-bootstrap";

class studentsList extends Component {
    render() {
        return (
            <div id="faculties">
            <section className="about-us  style-two wow fadeIn">
              <div className="auto-container">
                <div className="row">
                  <div className="info-column col-xl-10 col-lg-10 col-md-12 col-sm-12">
                    <div className="inner-column">
                      <div className="sec-title ">
                        <span className="title">
                          Shri Ramdawar Pandey Mahavidyalaya
                        </span>
                        <h2>Students</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <Form.Control as="select">
                      <option>B.A.</option>
                      <option>B.Sc.</option>
                      <option>M.A.</option>
                      <option>B.Ed.</option>
                      <option>D.El.Ed./BTC</option>
                    </Form.Control>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
    }
}

export default studentsList;