import React, { Component } from 'react';

class Faculty extends Component {
    render() {
        return (
            <section className="popular-places-section-two pt-0 wow fadeIn">
                <div className="placements-inner" style={{}}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 placements_box_title">
                                <div className="sec-title mb-3">
                                    <span className="title">SHRI RAMDAWAR PANDEY MAHAVIDYALAYA</span>
                                    <h2 className="h2faculty">Our Faculty</h2>
                                </div>
                                <div className="text ">
                                    Gaunt from the reputed universities of India, the highly qualified and experienced faculty is the greatest virtue of this college.
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-12 pt-5 pb-5 placements_box facultyBox">
                                <div className="popular-places-carousel ">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4">
                                            <div className="popular-item-two .faculty-single ">
                                                <div className="inner-box">
                                                    <div className="image-box">
                                                    </div>
                                                    <div className="info-box">
                                                        <h4 className="category"><a href="#/">Dr. I.P. Mishra</a></h4>
                                                        <span className="properties post">Principal</span>
                                                        <span className="properties">Ph.D.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="popular-item-two .faculty-single">
                                                <div className="inner-box">
                                                    <div className="image-box">
                                                    </div>
                                                    <div className="info-box">
                                                        <h4 className="category"><a href="#/">Dr. Neeraj Singh</a></h4>
                                                        <span className="properties post">HOD, B.Ed. Faculty</span>
                                                        <span className="properties">Ph.D.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="popular-item-two .faculty-single">
                                                <div className="inner-box">
                                                    <div className="image-box">
                                                    </div>
                                                    <div className="info-box">
                                                        <h4 className="category"><a href="#/">Dr. J.P. Mishra</a></h4>
                                                        <span className="properties post"> HOD, D.El.Ed. Faculty</span>
                                                        <span className="properties">Ph.D.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Faculty;