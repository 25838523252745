import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Placeholder from '../../../assets/images/thumbnail-default.jpg';
import courseimg from '../../../assets/images/course/coursesection.jpg';

class Course extends Component {
    render() {
        return (<section className="popular-places-section wow fadeIn">
            <div className="auto-container">
                <div className="sec-title">
                    <span className="title">SHRI RAMDAWAR PANDEY MAHAVIDYALAYA</span>
                    <h2>OUR UNIQUE PROGRAMS</h2>
                </div>
                <div className="programs-box">
                    <div className="vertical-tabs">
                        <div className="row no-gutters">
                            <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                                <div className="position-relative">
                                    <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                        <img src={courseimg} alt="course" />
                                    </LazyLoad>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="pag1" role="tabpanel">
                                        <div className="sv-tab-panel">
                                            <div className="spdetails coursesection">
                                                <p>We are providing grade education in congruous by integrating the best infrastructure so that students can become adroit professionals and be  welfare to society.</p>
                                                <ul className="plinks courses">
                                                    <li><Link to="/course"><i className="la la-suitcase" /> <span>B.A.</span></Link></li>
                                                    <li><Link to="/course"><i className="la la-suitcase" /> <span>B.Sc.</span> </Link></li>
                                                    <li><Link to="/course"><i className="la la-suitcase" /> <span>M.A.</span></Link></li>
                                                    <li><Link to="/course"><i className="la la-suitcase" /> <span>B.Ed.</span> </Link> </li>
                                                    <li><Link to="/course"><i className="la la-suitcase" /> <span>D.El.Ed.</span></Link> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
    }
}
export default Course;